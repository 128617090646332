/**
 * This middleware is used to pre-check if the wanted thematic page exists and have content.
 * If the page not exists, we forwarding to a dedicated store the Algolia results to avoid a second call.
 * If not exists, we redirecting to the search page with the wanted HTTP code.
 * If exists but has no content, we redirecting with an other HTTP code.
 */

import { defineNuxtRouteMiddleware } from '#imports'
import { callWithNuxt, navigateTo, useAsyncData, useNuxtApp } from 'nuxt/app'
import type { HaMultipleQueriesQueryUI } from '~/domains/search/interfaces/query.interface'
import type { HASearchResponse } from '~/domains/search/interfaces/search.interface'
import { convertParamsThematicToFilters } from '~/domains/thematic/helpers/filters.helper'
import { getPage, getPageTypeByFilters } from '~/domains/thematic/helpers/page.helper'
import { getCitiesResult, getSearchableResults, getTagResult, prepareContentPageQueryUI } from '~/domains/thematic/helpers/query.helper'
import { findSearchableBlocks } from '~/domains/thematic/helpers/search.helper'
import { PageType } from '~/domains/thematic/interfaces/page_type.interface'
import useThematicStore from '~/domains/thematic/stores/thematic.store'
import { ActivityTypes, getSeoCleanedData } from '~/helpers'
import { FilterKey } from '~/models'

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()

  // Fetch algolia
  if (nuxtApp.isHydrating) {
    return
  }

  const redirectToSearch = async (redirectCode: number) => {
    return await callWithNuxt(nuxtApp, () => {
      return navigateTo('/e/recherche?redirect=true', {
        redirectCode
      })
    })
  }

  const thematicStore = useThematicStore()

  const pageType = getPageTypeByFilters(to.params)

  //We deep clone the page object to add properties / fill the template
  const { page: pageTemplate } = getPage(pageType, to.params)

  const filtersThematic = convertParamsThematicToFilters(to.params)
  thematicStore.setFilters(filtersThematic)

  // Prepare query for page content
  const queriesContentPage = prepareContentPageQueryUI(filtersThematic)

  // Prepare queries for searchable blocks
  const queriesBlockSearchables = findSearchableBlocks(pageTemplate, filtersThematic)

  // Create searchables list and insert queries
  const searchables: HaMultipleQueriesQueryUI[] = []
  searchables.push(...queriesContentPage)
  searchables.push(...queriesBlockSearchables)

  for (const searchable of searchables) {
    searchable.params.facets = ['*']
  }

  const { data } = await useAsyncData<HASearchResponse[]>('thematic-middleware', () => {
    // @ts-expect-error - Le typage du plugin ne semble pas pris en compte.
    return nuxtApp.$algoliaClient.instance.fetch(searchables)
  }
  )

  thematicStore.setRawResults(data.value || [])

  const citiesResults = getCitiesResult(thematicStore.getRawResults)
  const tagsResults = getTagResult(thematicStore.getRawResults)
  const searchableResults = getSearchableResults(thematicStore.getRawResults)

  // Si la ville n'est pas trouvée côté Algolia, rediriger avec une 404.
  if ([PageType.REGION, PageType.DEPARTMENT, PageType.CITY].includes(pageType) && citiesResults?.nbHits === 0) {
    // eslint-disable-next-line no-console
    console.info('[MW > fetch thema] la localité n\'existe pas > redirect 404')
    return await redirectToSearch(404)
  }
  // Si la category n'exite pas
  else if ([PageType.CATEGORY, PageType.CATEGORY_IN_CITY, PageType.CATEGORY_IN_DEPARTMENT, PageType.CATEGORY_IN_REGION].includes(pageType) && tagsResults?.nbHits === 0) {
    // eslint-disable-next-line no-console
    console.info('[MW > fetch thema] la category n\'existe pas > redirect 404')
    return await redirectToSearch(404)
  }

  else if ([PageType.ACTIVITY_TYPE, PageType.ACTIVITY_TYPE_IN_CITY, PageType.ACTIVITY_TYPE_IN_DEPARTMENT, PageType.ACTIVITY_TYPE_IN_REGION].includes(pageType)) {
    const activityTypeParam = Array.isArray(filtersThematic.activity_type) ? filtersThematic.activity_type[0] : filtersThematic.activity_type

    const ActivityTypeExist = Object
      .keys(ActivityTypes)
      .includes(getSeoCleanedData(activityTypeParam) as ActivityTypes)

    if (!ActivityTypeExist) {
      // eslint-disable-next-line no-console
      console.info('[MW > fetch thema] l\'activité n\'existe pas > redirect 404')
      return await redirectToSearch(404)
    }
  }

  const mergedLocations: {
    region?: string,
    department?: string,
    city?: string
  } = {}

  const availableFacets = {}

  thematicStore.getRawResults.forEach((v) => {
    Object.assign(availableFacets, v.facets)
  })

  const availableRegionFacet = availableFacets[FilterKey.place_region]
  const availableDeptFacet = availableFacets[FilterKey.place_department]
  const availableCityFacet = availableFacets[FilterKey.place_city]

  if (availableRegionFacet) {
    const regionFacets = Object.keys(availableRegionFacet)
    if (regionFacets.length === 1) {
      mergedLocations.region = regionFacets[0]
    }
  }

  if (availableDeptFacet) {
    const deptFacets = Object.keys(availableDeptFacet)
    if (deptFacets.length === 1) {
      mergedLocations.department = deptFacets[0]
    }
  }

  if (availableCityFacet) {
    const cityFacets = Object.keys(availableCityFacet)
    if (cityFacets.length === 1) {
      mergedLocations.city = cityFacets[0]
    }
  }

  thematicStore.setLocation(mergedLocations)

  if (tagsResults?.nbHits) {
    thematicStore.setCategory(tagsResults.hits[0].tag_label)
  }

  if (!searchableResults.filter(r => r.nbHits).length) {
    // eslint-disable-next-line no-console
    console.info('[MW > fetch thema] tout les contenus Algolia sont vide > redirect 302')
    return await redirectToSearch(302)
  }
})
